import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styles from "../styles/contact.module.css";
import image from "../assets/gallery/180524174552.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

function Contact() {
    return (
        <div>
            <Header />
            <div className={styles.container}>
                <div className={styles.textConent}>
                    <h1>Contact</h1>
                    <p>Kanami Nakabayashi</p>
                    <p>kanamintimpe@gmail.com</p>
                    <p>346-370-2210</p>
                    <a href="https://www.instagram.com/kanamdance/"
                        target="_blank"
                        rel="noreferrer"
                        className={styles.instaIcon}>
                        <FontAwesomeIcon className={styles.instaIcon} icon={faInstagram} />
                    </a>
                </div>
                <img className={styles.image} src={image} alt="contact me"></img>
            </div>
            <Footer />
        </div>

    );
}

export default Contact;