import React from 'react';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styles from "../styles/home.module.css";
import promoVideo from "../assets/promoVideo.mp4";

function Home() {
    return (
        <div>
            <Header />
            <main className={`${styles.homePage} ${styles.mainGrid}`}>
                <video className={styles.homeVideo} muted autoPlay loop>
                    <source src={promoVideo} />
                </video>
                <section className={styles.homeMain}>
                    <h1 className={styles.homeTitle}>Kanami Nakabayashi</h1>
                    <p className={styles.homeSubtitle}>Dancer. Artist. Choreographer.</p>
                </section>
                <section className={styles.homeSecondary}>
                    <p className={styles.homeQuote}>"Dance is communication, and so the great challenge is to speak clearly, beautifully and with inevitability."</p>
                    <p className={styles.homeQuoteCredit}>-Martha Graham</p>
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default Home;