import React from "react";
import styles from "./footer.module.css";

function Footer() {
    return (
        <footer className={styles.footer}>
            <p className={styles.footerName}>Kanami Nakabayashi</p>
            <p className={styles.footerInfo}>kanamintimpe@gmail.com</p>
            <p className={styles.footerInfo}>346-370-2210</p>
            <p className={styles.footerCopyright}>©2020 by Kanami Nakabayashi and Connor Timpe</p>
        </footer>
    );
}

export default Footer;