import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styles from "../styles/performance.module.css";
import performanceData from "../assets/performance/performanceData";

function Performance() {

    return (
        <div>
            <Header />
            <h1 className={styles.title}>Performance</h1>
            <div className={styles.container}>
                {performanceData.map((item, key) =>
                    <div className={`${styles.card}  ${item.image ? "" : styles.noImage}`} key={key}>
                        <img src={item.image} alt={`${item.title}`} className={`${styles.image}`}></img>
                        <div className={styles.cardTextContent}>
                            <h2 className={styles.cardTitle}>{item.title}</h2>
                            <p className={styles.cardChoreographer}>{item.choreographer}</p>
                            <p className={styles.cardDancers}>{item.dancers}</p>
                            <p className={`${styles.cardPhotoCred} ${item.photoCred ? "" : styles.noPhotoCred}`}>{item.photoCred}</p>
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>

    );
}

export default Performance;

