import React from 'react';
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styles from "../styles/bio.module.css";
import headshot from "../assets/bio/bioHeadshot.jpg";
import CV from "../assets/bio/KanamiNakabayashiCV2020.pdf";

function Bio() {

    const bioText = `Kanami, originally from Tokyo, Japan, started dancing ballet at the age of three in Portland, Oregon. Upon returning back to Japan, she went to Noriko Kobayashi Ballet Academy and The Tokyo Ballet School to further her classical ballet training. In high school, she enrolled in professional program at the Quinte Ballet School of Canada, where she studied various dance styles including classical ballet, contemporary ballet, modern, contemporary, jazz, hip hop, and character dance. After graduating with the Quinte Ballet School of Canada Award, she went to The University of Texas at Austin, Theater and Dance Department. Majoring in Dance, she worked with various American choreographers including Charles O. Anderson, Alex Ketley, Abby Zbikowski, Kate Watson-Wallace and Sidra Bell.
    Upon graduation, she returned back to Japan and has performed in many projects by choreographers including Pichet Klunchun, Kota Yamazaki, Kazuyuki Futami, and Naoya Aoki and also choreographed her own work.
    Currently, she is dancing at Galveston Ballet as a Guest Artist.
    Her solo work, "Optimize Obligation" was selected for Barnstorm Dance Festival Digital Edition 2020.`;

    function openCV() {
        window.open(CV);
    }

    return (
        <div>
            <Header />
            <main className={styles.grid}>
                <section className={styles.content}>
                    <h1 className={styles.title}>BIO</h1>
                    <div className={styles.buttonContainer}>
                        {/* <a href="{CV}" className={`${styles.btn} ${styles.btnClear}`}
                            target="_blank"
                            rel="noreferrer">
                            CV
                        </a> */}
                        <button onClick={openCV} className={`${styles.btn} ${styles.btnClear}`}>
                            CV
                        </button>
                        <a href="https://www.youtube.com/watch?v=oCzxx84jQeY"
                            className={`${styles.btn} ${styles.btnPrimary}`}
                            target="_blank"
                            rel="noreferrer">
                            Promo Reel
                        </a>
                    </div>
                    <p className={styles.bioText}>{bioText}</p>
                </section>
                <img className={styles.headshot} src={headshot} alt="Headshot of Kanami"></img>
            </main>
            <Footer />

        </div>
    );
}

export default Bio;