import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styles from "../styles/commercialWorks.module.css";
import royaltyImage from "../assets/commercialWorks/royalty.JPG";
import loveInTheDarkImage from "../assets/commercialWorks/loveInTheDark.jpg";

function CommercialWorks() {
    return (
        <div>
            <Header />
            <h1 className={styles.title}>Commercial Works</h1>
            <div className={styles.cardContainer}>
                <img className={styles.image} src={royaltyImage} alt="Kanami being lifted horizontally by Connor"></img>
                <div className={styles.textContent}>
                    <h2 className={styles.cardTitle}>Royalty (Music Video)</h2>
                    <h3 className={styles.mainCredit}>Holidae</h3>
                    <div className={styles.credits}>
                        <p>Director of Photography: Ty Stevens</p>
                        <p>Videographer: Jay Bostwick</p>
                        <p>Dancers: Kanami Nakabayashi and Connor Timpe</p>
                        <p>Movement  Consultant: Tara Alperin</p>
                        <p>Director/HMU: Ashley Gold.</p>
                    </div>
                    <a
                        className={`${styles.btn} ${styles.btnDark}`}
                        href="https://www.youtube.com/watch?v=NYL7ZNEj-wo"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Video Link
                    </a>
                </div>
            </div>
            <div className={styles.cardContainer}>
                <img className={styles.image} src={loveInTheDarkImage} alt="Kanami and Hiroto looking at each other with a mirror in between."></img>
                <div className={`${styles.textContent} ${styles.loveInTheDarkText}`}>
                    <div className={styles.loveInTheDarkTitle}>
                        <h2 className={styles.cardTitle}>Love In The Dark (Dance Video)</h2>
                        <h3 className={styles.mainCredit}>Choreographed by Tetsuo Yamamoto</h3>
                    </div>
                    <div className={styles.loveInTheDarkBottomSection}>
                        <div className={`${styles.credits} ${styles.loveInTheDarkCredits}`}>
                            <p>Videography: Riku Hinohara</p>
                            <p>Dancers: Kanami Nakabayashi, Hiroto Mochizuki </p>
                            <p>Song : Love in the dark (Leroy Sanchez cover) - Adele</p>
                        </div>
                        <a
                            className={`${styles.btn} ${styles.btnDark}`}
                            href="https://www.youtube.com/watch?v=YhCfcmKuUd8"
                            target="_blank"
                            rel="noreferrer"
                        >
                            Video Link
                    </a>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}

export default CommercialWorks;