import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styles from "../styles/choreography.module.css";
import choreographyData from "../assets/choreography/choreographyData";

function Choreography() {

    return (
        <div>
            <Header />
            <h1 className={styles.title}>Choreography</h1>
            <div className={styles.container}>
                {choreographyData.map((item, key) =>
                    <div className={styles.card} key={key}>
                        <img
                            className={styles.image}
                            src={item.image}
                            alt={`${item.title}`}
                        ></img>
                        <div className={styles.textContent}>
                            <h2 className={styles.cardTitle}>{item.title}</h2>
                            <h3 className={styles.choreographer}>{item.choreographer}</h3>
                            <p className={styles.description}>{item.description}</p>
                            <p className={`${item.photoCred ? styles.photoCred : styles.noPhotoCred}`}>{item.photoCred}</p>
                        </div>
                    </div>
                )}
            </div>

            <Footer />
        </div>
    );
}

export default Choreography;
