import React from "react";
import styles from "../styles/teachingExperience.module.css";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";

function TeachingExperience() {
    return (
        <div>
            <Header />
            <div className={styles.container}>
                <h1 className={styles.title}>Teaching Experience</h1>
                <div className={styles.itemContainer}>
                    <div className={styles.item}>
                        <h2>{"Youth Theater Japan"}</h2>
                        <h3 className={styles.itemYear}>{"2018 - 2019"}</h3>
                        <p className={styles.itemDescription}>
                            {
                                "Kindergarten Ballet, Kindergarten Dance, Grade 1-2, 3-6, 7-9 Dance"
                            }
                        </p>
                    </div>
                    <div className={styles.item}>
                        <h2>{"Angelina Ballet Studio"}</h2>
                        <h3 className={styles.itemYear}>{"2017 - 2019"}</h3>
                        <p className={styles.itemDescription}>
                            {
                                "Grade 3-7 Intermediate Ballet"
                            }
                        </p>
                        <p className={styles.itemDescription}>
                            {
                                "Kindergarten, Grade 1-2 Beginner Ballet Assistant"
                            }
                        </p>
                    </div>
                    <div className={styles.item}>
                        <h2>{"Private English Teaching"}</h2>
                        <h3 className={styles.itemYear}>{"2020 - Present"}</h3>
                        <p className={styles.itemDescription}>
                            {
                                "Change this"
                            }
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>

    );
}

export default TeachingExperience;