import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styles from "../styles/gallery.module.css";
import galleryImages from "../assets/gallery/images";

function Gallery() {


    return (
        <div>
            <Header />
            <h1 className={styles.title}>Gallery</h1>
            <div className={styles.container}>
                {galleryImages.map((image, index) => 
                    <img className={styles.images} src={image} alt={"Performances"} key={index}></img>
                )}
                
            </div>
            <p className={styles.credit}>Photos by: Lawrence Peart, Seiya Honna, Bozzo</p>
            <Footer />
        </div>

    );
}

export default Gallery;