import img1 from "./0920 Kana and Vero_54.jpg";
import img2 from "./12250179_10153156502855824_7238282123599951908_n.jpg";
import img3 from "./171216ayatori_123.jpg";
import img4 from "./171216ayatori_133.jpg";
import img5 from "./180524174552.jpg";
import img7 from "./Bodies and Souls _ Lawrence Peart 230.jpg";
import img9 from "./DSC07661.JPG";
import img10 from "./Kanami Dance photo.jpg";
import img11 from "./mixg day2_201103.jpg";
import img12 from "./mixg day2_201103_0.jpg";
import img13 from "./mixg day2_201103_2.jpg";
import img14 from "./mixg_190929_0040.jpg";
import img15 from "./SEIYA cam_100.jpg";

const galleryImages = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img7,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
]

export default galleryImages;